import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";
import {Tab, Tabs} from "react-bootstrap";
import {Link} from "react-router-dom";

const ExamSection = (props) => {
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection
                mainTitle="Exam Section"
                subTitle="Sardar Beant State University, Gurdaspur"
                bdLink="Exam Section "
            />

            <div className="section3">
                <div className="container pt-90">
                    <h1 className="text-theme-colored2">Examination Section</h1>
                    <div className="double-line-bottom-theme-colored-2"></div>

                    <div className={"row"}>
                        <div className={"col-sm-6"}></div>
                        <div className={"col-sm-6 text-center"}>
                            {/*<a href="../assets/Downloads/OfficeBearers.pdf" target={"_blank"}>*/}
                            {/*    <button className={"btn btn-md btn-theme-colored2"}>*/}
                            {/*        Exam Section Office Bearers*/}
                            {/*    </button>*/}
                            {/*</a>*/}

                            <a href="mailto:coe@sbssugsp.ac.in" className="btn btn-md btn-theme-colored2"
                               style={{textTransform: 'lowercase'}}>
                                coe@sbssugsp.ac.in
                            </a>
                        </div>
                    </div>

                    <br/>

                    <div className={"row table-responsive"}>
                        <div className={"col-sm-2"}></div>
                        <div className={"col-sm-7"}>
                            {/* -- Examination Officials -- */}
                            <table className={"table table-striped"}>
                                <thead className="table-head-red-V">
                                <tr>
                                    <th colSpan="2" className="text-center h4">Examination Officials</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th className={"text-right"}>Controller of Examinations:</th>
                                    <td className={"text-left"}>Sh. A.K.Dogra</td>
                                    {/*<td className={"text-center"}>+91 8847549049</td>*/}
                                </tr>

                                <tr>
                                    <th className={"text-right"}>OIC (Secrecy):</th>
                                    <td className={"text-left"}>Sh. Mohit Marwaha</td>
                                </tr>

                                <tr>
                                    <th className={"text-right"}>OIC (Conduct):</th>
                                    <td className={"text-left"}>Dr. Naveen Beri</td>
                                </tr>

                                <tr>
                                    <th className={"text-right"}>OIC (Evaluation):</th>
                                    <td className={"text-left"}>Dr. Sunil Kumar</td>
                                </tr>

                                <tr>
                                    <th className={"text-right"}>OIC (Results):</th>
                                    <td className={"text-left"}>Dr. Pallavi Mahajan</td>
                                </tr>

                                <tr>
                                    <th className={"text-right"}>OIC (DMC & Degree):</th>
                                    <td className={"text-left"}>Dr. Rajesh Sharma</td>
                                </tr>

                                {/*<tr>*/}
                                {/*  <th className={"text-center"}>*/}
                                {/*    Incharge, Results & Evaluation :{" "}*/}
                                {/*  </th>*/}
                                {/*  <td className={"text-center"}> Dr. Naveen Beri</td>*/}
                                {/*  <td className={"text-center"}>+91 8727099709</td>*/}
                                {/*</tr>*/}

                                {/*<tr>*/}
                                {/*  <th className={"text-center"}>*/}
                                {/*    Associate Incharge, Results & Evaluation :{" "}*/}
                                {/*  </th>*/}
                                {/*  <td className={"text-center"}> Smt. Anita Suman</td>*/}
                                {/*  <td className={"text-center"}>+91 9464068400</td>*/}
                                {/*</tr>*/}

                                {/*<tr>*/}
                                {/*  <th className={"text-center"}>*/}
                                {/*    Incharge, Secrecy & Conduct :{" "}*/}
                                {/*  </th>*/}
                                {/*  <td className={"text-center"}> Sh. Ajay Kumar Dogra</td>*/}
                                {/*  <td className={"text-center"}>+91 9914318148</td>*/}
                                {/*</tr>*/}

                                {/*<tr>*/}
                                {/*  <th className={"text-center"}>*/}
                                {/*    Associate Incharge, Secrecy & Conduct :{" "}*/}
                                {/*  </th>*/}
                                {/*  <td className={"text-center"}> Smt. Neetu Sagar </td>*/}
                                {/*  <td className={"text-center"}>+91 9872204441</td>*/}
                                {/*</tr>*/}

                                {/*<tr>*/}
                                {/*  <th className={"text-center"}>*/}
                                {/*    System Administrator ERP :{" "}*/}
                                {/*  </th>*/}
                                {/*  <td className={"text-center"}> Sh. Rajeev Kumar Bedi </td>*/}
                                {/*  <td className={"text-center"}>+91 7696168094</td>*/}
                                {/*</tr>*/}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <h4 className="text-theme-colored2">Important Notices/Information</h4>
                    <div className="double-line-bottom-theme-colored-2"></div>

                    <div className="table-responsive">
                        <table className="table-hover table margin-25-V">
                            <thead className="table-head-red-V">
                            <tr>
                                <th className="table-title-V">Notices/Information Title</th>
                                <th className="table-title-V">View</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr>
                                <td valign="top">Attendance for External Practical Viva</td>
                                <td>
                                    <a
                                        target="_blank"
                                        href="assets/pdf/examination-performas/Attendance-for-External-Practical-Viva.pdf"
                                        className="btn theme-btn myButton_A"
                                    >
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    Attendance for Written Practical Examination
                                </td>
                                <td>
                                    <a
                                        target="_blank"
                                        href="assets/pdf/examination-performas/Attendance-for-Written-Practical-Examination.pdf"
                                        className="btn theme-btn myButton_A"
                                    >
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">Bill for TA-Honrarium</td>
                                <td>
                                    <a
                                        target="_blank"
                                        href="assets/pdf/examination-performas/Bill-for-TA-Honrarium.pdf"
                                        className="btn theme-btn myButton_A"
                                    >
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    Manual Performa for Reappear Examination Form
                                </td>
                                <td>
                                    <a
                                        target="_blank"
                                        href="assets/pdf/examination-performas/Manual-Performa-for-Reappear-Examination-Form.pdf"
                                        className="btn theme-btn myButton_A"
                                    >
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    Performa Remuneration Bill for Evaluation of Answer Sheets
                                </td>
                                <td>
                                    <a
                                        target="_blank"
                                        href="assets/pdf/examination-performas/Performa-REMUNERATION-BILL-FOR-EVALUATORS-OF-ANSWER-SHEETS.pdf"
                                        className="btn theme-btn myButton_A"
                                    >
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    Performa Remuneration Bill for Head Examiner
                                </td>
                                <td>
                                    <a
                                        target="_blank"
                                        href="assets/pdf/examination-performas/Performa-Remuneration-Bill-for-Head-Examiner.pdf"
                                        className="btn theme-btn myButton_A"
                                    >
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    Rem Bill for External Examiner for Practical Examination
                                </td>
                                <td>
                                    <a
                                        target="_blank"
                                        href="assets/pdf/examination-performas/Rem-bill-for-external-examiner-for-Practical-Examination.pdf"
                                        className="btn theme-btn myButton_A"
                                    >
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    Remuneration Bill for Examiners of Practical Exam
                                </td>
                                <td>
                                    <a
                                        target="_blank"
                                        href="assets/pdf/examination-performas/REMUNERATION-BILL-FOR-EXAMINERS-OF-PRACTICAL-EXAM.pdf"
                                        className="btn theme-btn myButton_A"
                                    >
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td valign="top">
                                    Remuneration Bill for External Paper Setter
                                </td>
                                <td>
                                    <a
                                        target="_blank"
                                        href="assets/pdf/examination-performas/Remuneration-bill-for-External-Paper-setter.pdf"
                                        className="btn theme-btn myButton_A"
                                    >
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td valign="top">
                                    Remuneration Bill For Internal Paper Setter
                                </td>
                                <td>
                                    <a
                                        target="_blank"
                                        href="assets/pdf/examination-performas/REMUNERATION-BILL-FOR-INTERNAL-PAPER-SETTER.pdf"
                                        className="btn theme-btn myButton_A"
                                    >
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td valign="top">Admit Card Cum Exam form REGULAR BATCH 2021 Manual SBSSU Format
                                    Dec-2022
                                </td>
                                <td>
                                    <a
                                        target="_blank"
                                        href="assets/pdf/examination-performas/Admit-Card-Cum-Exam-form-REGULAR-BATCH-2021-Manual-SBSSU-Format-Dec-2022.pdf"
                                        className="btn theme-btn myButton_A"
                                    >
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td valign="top">
                                    {/*Admit Card Cum Exam form REAPPEAR BATCH 2021 Manual SBSSU Format Dec-2022*/}
                                    SBSSU Reappear Admit Card Cum Exam form Reappear SBSSU June-2024 Examination
                                </td>
                                <td>
                                    <a target="_blank"
                                       href="/assets/pdf/examination-performas/SBSSU-Reappear-Admit-Card-Cum-Exam-form-Reappear-SBSSU-June-2024-Examination.pdf"
                                       className="btn theme-btn myButton_A">
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td valign="top">Reappear Detail</td>
                                <td>
                                    <a
                                        target="_blank"
                                        href="assets/pdf/examination-performas/MANL-Reappear-Form-for-PTU-Batches.pdf"
                                        className="btn theme-btn myButton_A">
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td valign="top">External Theory Award Performa</td>
                                <td>
                                    <a
                                        target="_blank"
                                        href="assets/pdf/examination-performas/external-theory-award-performa.pdf"
                                        className="btn theme-btn myButton_A">
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td valign="top">
                                    Amendment-II in date sheet with new addition of subjects
                                </td>
                                <td>
                                    <a target="_blank" className="btn theme-btn myButton_A"
                                       href="/assets/pdf/examination-performas/Amendment-II-in-date-sheet-with-new-addition-of-subjects.pdf">
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td valign="top">
                                    Amendment-III in Date Sheet for End Semester Examination December-2023
                                </td>
                                <td>
                                    <a target="_blank" className="btn theme-btn myButton_A"
                                       href="/assets/pdf/examination-performas/Amendment-III-in-Date-Sheet-for-End-Semester-Examination-December-2023.pdf">
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td valign="top">
                                    Amendment-IV in Date Sheet Dec 2023 Examination
                                </td>
                                <td>
                                    <a target="_blank" className="btn theme-btn myButton_A"
                                       href="/assets/pdf/examination-performas/Amendment-IV-in-Date-Sheet-Dec-2023-Examination.pdf">
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td valign="top">Internal Assessment Improvement Form</td>
                                <td>
                                    <a target="_blank" className="btn theme-btn myButton_A"
                                       href="/assets/pdf/examination-performas/INTERNAL-ASSESSMENT-IMPROVEMENT-FORM.pdf">
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td valign="top">Date Sheet Part II (Dec-2024 Examination), REAPPEAR</td>
                                <td>
                                    <a target="_blank" className="btn theme-btn myButton_A"
                                       href="/assets/pdf/examination-performas/Date-Sheet-Part-II-REAPPEAR.pdf">
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a>
                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
};

export default ExamSection;
