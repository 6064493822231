import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

const BtechMechEng = (props) => {
    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Undergraduate Courses" subTitle="B.Tech(Mechanical Engg.)" bdLink="Courses"/>
            <div className="section3">
                <div className="container pt-90">
                    {/*  Main Area  */}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2"> Bachelor of Technology: Mechanical
                            Engineering</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  Details  */}
                        <div className="row">
                            <div className="padd-10-v">
                                <p className=" font-p-v m-7-v">
                                    The department of mechanical engineering offers four years course at undergraduate
                                    level leading to the award of B. Tech. degree in the discipline of Mechanical
                                    Engineering. In this four-year degree programme, students will learn theory and
                                    practice in the core areas of mechanical engineering such as manufacturing ,
                                    refrigeration and air conditioning systems, design of machinery and industrial
                                    equipment to name a few.
                                </p>
                            </div>
                            <div className="col-md-8  padd-10-v">
                                <p className=" font-p-v">
                                    Students will also learn the principles of energy
                                    efficiency and use of computers for design and manufacturing. Students will also
                                    complete a major Mechanical Engineering Project, working closely with faculty
                                    members and industry partners of the university.
                                    BTech Mechanical Engineering is a 4 year undergraduate engineering degree course.
                                    This course prepares the students to become Mechanical Engineers. The objective of
                                    this course is to prepare students to apply the principles of mechanical engineering
                                    for designing, manufacturing, and maintenance of mechanical systems.
                                </p>


                            </div>
                            <div className="col-md-4 text-center">
                                <div className="imageEffect-v">
                                    <img src="./assets/images/admission_icons/btechMechEng.jpeg" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Eligibility */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Eligibility Criteria</h2>
                            <p className="font-p-v m-7-v">
                                Passed the 10+2 examination from a board recognized or established by central/state
                                government through a legislation and a member of Boards of School Education (COBSE), New
                                Delhi with Physics/ Mathematics/ Chemistry / Biotechnology / Computer Science / Biology
                                / Electronics/ Information Technology/ Informatics Practices / Technical Vocational
                                Subject / Agriculture / Engineering Graphics / Entrepreneurship (Any of three). Obtained
                                at least 45% marks (40% in case of Candidates belonging to reserved category) in the
                                above subject taken together.
                            </p>
                            <div className="text-center  m-7-v">
                                <h5 className="text-theme-colored2">--- OR ---</h5>
                            </div>
                            <p className="font-p-v m-7-v">
                                Passed Diploma in any Engineering Trade from Punjab State Board of Technical Education &
                                Industrial Training, Chandigarh or Sant Longowal Institute of Engineering and
                                Technology, Longowal (SLIET), or any such examination from any other recognized State
                                Board of Technical Education with atleat 45% marks (40% in case of candidates belonging
                                to reserved category).
                            </p>
                            <div className="text-center  m-7-v">
                                <h5 className="text-theme-colored2">--- OR ---</h5>
                            </div>
                            <p className="font-p-v m-7-v">
                                The candidates who have passed two years certificate course from Sant Longowal Institute
                                of Engineering and Technology, Longowal (SLIET) shall be eligible.
                            </p>

                        </div>
                    </div>

                    {/*  Duration  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Duration</h2>
                            <p className="font-p-v m-7-v">
                                The Course will be 4 years long, with Eight Semesters
                            </p>
                        </div>
                    </div>

                    {/*  Syllabus  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Syllabus</h2>
                            <p className="font-p-v m-7-v">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V padd-l-10">Scheme</th>
                                        <th className="table-title-V padd-l-36">Syllabus</th>
                                        <th className="table-title-V">Remarks</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {/* 2023 */}
                                    {/*<tr>*/}
                                    {/*    <td className="table-body-padd-V syllabus-table-v">2023</td>*/}
                                    {/*    <td className="table-body-padd-V syllabus-table-v">*/}
                                    {/*        <a className="btn margin-x-25-v theme_btn_V theme-btn"*/}
                                    {/*           href="/assets/pdf/admissions/UG/BTech/revised-syllabus-Scheme-2023-merged.pdf"*/}
                                    {/*           target="_blank">*/}
                                    {/*            <i className="fa fa-file-pdf-o padd-10-v"></i>*/}
                                    {/*            <span className="hide-v">Download Syllabus</span>*/}
                                    {/*        </a>*/}
                                    {/*    </td>*/}
                                    {/*    <td className="table-body-padd-V syllabus-table-v">Batch 2023 Onwards</td>*/}
                                    {/*</tr>*/}

                                    {/* 2023 - 3rd & 4th Sem. */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">3rd to 8th Semester</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/academic/MechEng/BTech/B-Tech-Mechanical-Syllabus-2023_3rd_and_4th_sem.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2023</td>
                                    </tr>

                                    {/* 2023 - First Year */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">First Year</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/admissions/UG/BTech/revised-syllabus-scheme-2023-merged-first-year.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2023 Onwards</td>
                                    </tr>

                                    {/* 2021 */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2021</td>
                                        <td className="table-body-padd-V syllabus-table-v">
                                            <a className="btn margin-x-25-v theme_btn_V theme-btn"
                                               href="/assets/pdf/academic/MechEng/BTech/B-Tech-Mechanical-Syllabus-2021-batch-onwards.pdf"
                                               target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i>
                                                <span className="hide-v"> Download Syllabus</span>
                                            </a>
                                        </td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2021 Onwards</td>
                                    </tr>

                                    {/* 2020 */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2020</td>
                                        <td className="table-body-padd-V syllabus-table-v">
                                            <a className="btn margin-x-25-v theme_btn_V theme-btn"
                                               href="/assets/pdf/academic/MechEng/BTech/B-Tech-Mechanical-Syllabus-2020-batch-onwards.pdf"
                                               target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i>
                                                <span className="hide-v"> Download Syllabus</span>
                                            </a>
                                        </td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2020 Onwards</td>
                                    </tr>

                                    {/* 2018 */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2018</td>
                                        <td className="table-body-padd-V syllabus-table-v">
                                            <a className="btn margin-x-25-v theme_btn_V theme-btn"
                                               href="/assets/pdf/academic/MechEng/BTech/B-Tech-Mechanical-Syllabus-2018-batch-onwards.pdf"
                                               target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i>
                                                <span className="hide-v"> Download Syllabus</span>
                                            </a>
                                        </td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2018 Onwards</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    );
}

export default BtechMechEng;